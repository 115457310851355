import router, { constantRoutes, permissionRoutes } from '../router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { Route } from 'vue-router';
import { store } from '@/store';

import { authService } from '@/modules/account/services/authService';

import { Message } from 'element-ui';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';

NProgress.configure({ showSpinner: false });

let loadPermissionRouteClaims = false;
const whiteList = ['/login', '/register', '/recoverPassword', '/changeCredential', '/addCredential', '/resetPassword', '/emailConfirm', '/albo-unico'];
router.beforeEach(async (to: Route, _: Route, next: any) => {
    // Start progress bar
    NProgress.start();
    // Determine whether the user has logged in
    const isAuthenticated = await authService.GetAccessToken();
    if (!!isAuthenticated) {
        // load user info
        try {
            await authService.WhoAmI();
            await authService.GetClaims();
            await periodoFormativoService.List();
        } catch (err) {
            await authService.Logout();
            next({ path: `/login?redirect=${to.path}` });
            //next({ path: '/login' });
            loadPermissionRouteClaims = false;
            NProgress.done();
        }

        if (to.path === '/login') {
            // If is logged in, redirect to the home page
            next({ path: '/', query: to.query});
            NProgress.done();
        } else {
            // Check whether the user has obtained his permission roles
            let claims = store.state.login.claims || [];
            if (claims.length <= 0 && loadPermissionRouteClaims) {
                // Remove token and redirect to login page
                await authService.Logout();
                Message.error(`L'utente non ha i permessi necessari ad accedere!`);
                next({ path: '/login' });
                loadPermissionRouteClaims = false;
                NProgress.done();
            } else {
                if (!loadPermissionRouteClaims) {

                    claims = await authService.GetClaims();

                    loadPermissionRouteClaims = true;
                    // Generate accessible routes map based on claims
                    store.actions.permission.generateRoutes(claims);
                    // Dynamically add accessible routes
                    store.state.permission.dynamicRoutes.forEach(route => {
                        router.addRoute(route);
                    });

                    // Hack: ensure addRoutes is complete
                    // Set the replace: true, so the navigation will not leave a history record
                    next({ ...to, replace: true });
                } else {
                    if (router.getMatchedComponents(to).length > 0) {
                        next();
                    } else {
                        const allRoutes = permissionRoutes().concat(constantRoutes()).map(value =>
                            [value.path, ...value.children.map(child => child.path)]
                        ).flat();

                        if (allRoutes.includes(to.path))
                            next({ path: "/401", replace: true });
                        else
                            next({ path: "/404", replace: true });
                    }
                }
            }
        }
    } else {
        // Has no token
        if (whiteList.some(s => to.path.indexOf(s) !== -1)) {
            // In the free login whitelist, go directly
            next();
        } else {
            // // Other pages that do not have permission to access are redirected to the login page.
            next({ path: `/login?redirect=${to.path}` });
            // next({ path: '/login' });
            loadPermissionRouteClaims = false;
            NProgress.done();
        }
    }
});

router.afterEach((to: Route) => {
    NProgress.done();
    document.title = getPageTitle(to.meta.title);
});

const getPageTitle = (title: string) => {
    const baseTitle = `Formazione Giornalisti ODG`
    if (title)
        return `${title} - ${baseTitle}`;
    return `${baseTitle}`;
};
