import { ActionTree, GetterTree } from "vuex";
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

export interface LoginStoreActions {
  setMyUserInfo(model: user.whoAmI);
  setClaims(model: string[]);
}
export interface LoginStoreGetters {
  userInfo: () => user.whoAmI
  hasPermission: (id) => boolean
}
export interface LoginStoreModel {
  userInfo: user.whoAmI,
  claims: string[]
}

export const loginStore = {
  PREFIX: "login",
  namespaced: true,
  state: {
    userInfo: null,
    claims: [],
  } as LoginStoreModel,
  getters: {
    userInfo: (state: LoginStoreModel) => (): user.whoAmI => state.userInfo,
    hasPermission: (state: LoginStoreModel) => (id): boolean => !!state.claims.find(c => c == id)
  } as GetterTree<LoginStoreModel, LoginStoreModel>,
  mutations: {
    SET_MyUserInfo(state: LoginStoreModel, model: user.whoAmI) {
      state.userInfo = model;
    },
    SET_CLAIMS(state: LoginStoreModel, model: string[]) {
      state.claims = model;
    }
  },
  actions: {
    setMyUserInfo({ commit }, model: user.whoAmI) {
      commit("SET_MyUserInfo", model)
    },
    setClaims({ commit }, model: string[]) {
      commit("SET_CLAIMS", model)
    }
  } as ActionTree<LoginStoreModel, any>,
};



