import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import ResizeMixin from './mixin/resize';

@Component({
  name: 'AnonymousLayout',
})
export default class extends mixins(ResizeMixin) {
  // mounted() {
  //   let scriptNode = document.createElement('script')
  //   scriptNode.innerText = `
  //   (function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
  //     'https://teamdev.ladesk.com/scripts/track.js',
  //     function(e){ LiveAgent.createKbSearchWidget('iu3o6ugu', e); });
  //   `;
  //   document.body.prepend(scriptNode)
  // }

  get classObj() {
    return {
      hideSidebar: true,
      openSidebar: false,
      withoutAnimation: false
    };
  }

  get key() {
    return this.$route.path;
  }
}
