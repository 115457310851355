import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import CleanLayout from "@/layout/clean.vue";
import AnonymousLayout from "@/layout/anonymous.vue";
import AuthorizedLayout from "@/layout/web.vue";
import settings from '@/settings';


Vue.use(VueRouter)

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    claims: ['claim1', 'claim2']   will control the permission claims (allow setting multiple claims)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'icon class name'      the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

export function constantRoutes(): RouteConfig[] {
  return [
    {
      path: '/anonymous',
      component: AnonymousLayout,
      redirect: '/login',
      meta: { hidden: true },
      children: [{
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/modules/account/views/login.vue'),
        meta: {
          title: `Accedi`,
          hidden: true
        }
      },
      {
        path: '/recoverPassword',
        name: 'recoverPassword',
        component: () => import(/* webpackChunkName: "recoverPassword" */ '@/modules/account/views/recoverPassword.vue'),
        meta: {
          title: `Recupera password`,
          hidden: true
        },
        props: true
      },
      {
        path: '/changeCredential',
        name: 'changeCredential',
        component: () => import(/* webpackChunkName: "changeCredential" */ '@/modules/account/views/changeCredential.vue'),
        meta: {
          title: `Cambia credenziali di accesso`,
          hidden: true
        }
      },
      {
        path: '/register/:codiceFiscale',
        name: 'register',
        component: () => import(/* webpackChunkName: "changeCredential" */ '@/modules/account/views/register.vue'),
        meta: {
          title: `Registrati`,
          hidden: true
        },
        props: true
      },
      {
        path: '/addCredential/:userId/:code',
        name: 'addCredential',
        component: () => import(/* webpackChunkName: "addCredential" */ '@/modules/account/views/addCredential.vue'),
        meta: {
          title: `Aggiungi credenziali`,
          hidden: true
        },
        props: true
      },
      {
        path: '/resetPassword/:userId/:code',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "resetPassword" */ '@/modules/account/views/resetPassword.vue'),
        meta: {
          title: `Reset password`,
          hidden: true
        },
        props: true
      },
      {
        path: '/emailConfirm/:userId/:code',
        name: 'emailConfirm',
        component: () => import(/* webpackChunkName: "emailConfirm" */ '@/modules/account/views/emailConfirm.vue'),
        meta: {
          title: `Conferma E-Mail`,
          hidden: true
        },
        props: true
      },
      {
        path: '404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
        meta: { hidden: true }
      },
      {
        path: '401',
        component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
        meta: { hidden: true }
      }]
    },
    {
      name: 'dashboard',
      path: '/',
      component: AuthorizedLayout,
      redirect: '/dashboard',
      children: [
        {
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            title: 'Home',
            icon: 'fas fa-tachometer-alt',
            hidden: false
          }
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "profile" */ '@/modules/profile/index.vue'),
          meta: {
            title: 'Profilo utente',
            icon: 'fas fa-user',
            hidden: true
          }
        },
        {
          path: '404',
          component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
          meta: { hidden: true }
        },
        {
          path: '401',
          component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
          meta: { hidden: true }
        }
      ]
    },
    {
      name: 'albo-unico',
      path: '/albo-unico',
      component: CleanLayout,
      redirect: '/albo-unico',
      children: [
        {
          component: () => import(/* webpackChunkName: "dashboard" */ '@/modules/albo-iscritti/index.vue'),
          path: '/albo-unico/:ordine?',
          name: 'albo-unico',
          meta: {
            title: `Albo Unico`,
            hidden: true
          },
          props: true
        }
      ]
    }
  ]
};

export function permissionRoutes(): Array<RouteConfig> {
  return [
    {
      name: 'corsi',
      path: '/corsi',
      component: AuthorizedLayout,
      redirect: '/corsi/lista',
      meta: {
        title: "Corsi",
        icon: "fas fa-chalkboard-teacher",
        claims: ['CORSO.viewAll', 'CORSO.viewOrg', 'CORSO.viewMine']
      },
      children: [{
        name: 'corsi',
        path: '/corsi/lista',
        component: () => import(/* webpackChunkName: "corsi" */ '@/modules/corsi/views/corsi.vue'),
        meta: {
          title: `Gestione Corsi`,
          icon: "fas fa-chalkboard-teacher",
        }
      }, {
        name: 'modificaCorso',
        path: '/corsi/:id',
        component: () => import(/* webpackChunkName: "corsi" */ '@/modules/corsi/views/corso.vue'),
        meta: {
          title: `Modifica corso`,
          hidden: true
        },
        props: true
      }]
    },
    {
      name: 'creditiSpeciali',
      path: '/crediti-speciali',
      component: AuthorizedLayout,
      redirect: '/crediti-speciali/lista',
      meta: {
        claims: ["CREDSPECIALI.viewAll", "CREDSPECIALI.viewOrg"]
      },
      children: [{
        name: 'creditiSpecialiLista',
        path: '/crediti-speciali/lista',
        //component: () => import(/* webpackChunkName: "corsi" */ '@/modules/corsi/views/corsi.vue'),
        component: () => import(/* webpackChunkName: "creditiSpeciali" */ '@/modules/creditiSpeciali/views/creditiSpeciali.vue'),
        meta: {
          title: `Crediti Speciali`,
          icon: "fas fa-medal",
        }
      }]
    },
    {
      name: 'giornalistaCorsi',
      path: '/giornalisti/corsi',
      component: AuthorizedLayout,
      redirect: '/giornalisti/corsi/aperti',
      meta: {
        title: "Corsi",
        icon: `fas fa-school`,
        claims: ['GIORNALISTI.corsiAperti', 'GIORNALISTI.iMieiCorsi']
      },
      children: [
        {
          name: "giornalistiCorsiAperti",
          path: "/giornalisti/corsi/aperti",
          component: () => import(/* webpackChunkName: "giornalisti-corsi" */ '@/modules/giornalisti/views/corsiAperti.vue'),
          meta: {
            title: `Corsi Disponibili`,
            icon: `fas fa-school`,
            claims: ['GIORNALISTI.corsiAperti']
          }
        },
        {
          name: "giornalistiCorsiProroga",
          path: "/giornalisti/corsi/proroga",
          component: () => import(/* webpackChunkName: "giornalisti-corsi" */ '@/modules/giornalisti/views/corsiProroga.vue'),
          meta: {
            title: `Corsi Disponibili in Proroga`,
            icon: `fas fa-school`,
            claims: ['GIORNALISTI.corsiAperti'],
            adempientePeriodoFormativoPrecedente: false
          }
        },
        {
          name: "giornalistaIMieiCorsi",
          path: "/giornalisti/corsi/giornalista",
          component: () => import(/* webpackChunkName: "giornalisti-corsi" */ '@/modules/giornalisti/views/iMieiCorsi.vue'),
          meta: {
            title: `I Miei Corsi`,
            icon: `fas fa-school`,
            claims: ['GIORNALISTI.iMieiCorsi']
          }
        },
        {
          name: "giornalistiCorsoDetail",
          path: "/giornalisti/corsi/dettaglio/:corsoId/:anagraficaGiornalistaId?",
          component: () => import(/* webpackChunkName: "giornalisti-corsi" */ '@/modules/giornalisti/components/dettaglioCorso/dettaglioCorso.vue'),
          meta: {
            title: `Dettaglio corso`,
            hidden: true
          },
          props: true,
        }, {
          name: 'conferma',
          path: '/giornalisti/corsi/conferma-invito/:corsoId/:anagraficaGiornalistaId?',
          component: () => import(/* webpackChunkName: "enti-aziende" */ '@/modules/giornalisti/views/confermaIscrizione.vue'),
          meta: {
            title: 'Conferma iscrizione',
            hidden: true
          },
          props: true
        }]
    },
    // {
    //   path: 'external-link',
    //   component: AuthorizedLayout,
    //   meta: {
    //     title: "Corsi",
    //     icon: `fas fa-school`,
    //     claims: ['GIORNALISTI.corsiOnline']
    //   },
    //   children: [
    //     {
    //       path: `${settings.connectAuthorizeMoodleUrl}`,
    //       meta: { title: 'Corsi Online', icon: 'fas fa-external-link-alt' }
    //     }
    //   ]
    // },
    {
      name: 'giornalisti',
      path: '/giornalisti',
      component: AuthorizedLayout,
      redirect: '/giornalisti/lista',
      meta: {
        title: `Giornalisti`,
        icon: `fas fa-user-graduate`,
        claims: ['ANAGRAFICA_GIORNALISTI.viewAll', 'ANAGRAFICA_GIORNALISTI.viewOrg']
      },
      children: [{
        name: 'giornalisti',
        path: '/giornalisti/lista',
        component: () => import(/* webpackChunkName: "anagrafica" */ '@/modules/anagrafica/views/anagrafica.vue'),
        meta: {
          title: `Giornalisti`,
          icon: `fas fa-user-graduate`,
          claims: ['ANAGRAFICA_GIORNALISTI.viewAll', 'ANAGRAFICA_GIORNALISTI.viewOrg']
        }
      }]
    },
    {
      name: 'offerta-formativa',
      path: "/offerta-formativa",
      component: AuthorizedLayout,
      redirect: '/offerta-formativa/periodo-formativo',
      meta: {
        title: "Offerta formativa",
        icon: "fas fa-graduation-cap",
        claims: ['OFFERTA_FORMATIVA.PF.enabled', 'OFFERTA_FORMATIVA.POF.enabled']
      },
      children: [{
        name: 'periodo-formativo',
        path: '/offerta-formativa/periodo-formativo',
        component: () => import(/* webpackChunkName: "periodo-formativo" */ '@/modules/offertaFormativa/views/periodoFormativo.vue'),
        meta: {
          title: `Periodo formativo`,
          icon: "fas fa-graduation-cap",
          claims: ['OFFERTA_FORMATIVA.PF.enabled']
        }
      }, {
        name: 'pof',
        path: '/offerta-formativa/piano-offerta-formativa/:periodoFormativoId?',
        component: () => import(/* webpackChunkName: "pof" */ '@/modules/offertaFormativa/views/pianoOffertaFormativa.vue'),
        meta: {
          title: `Piani offerta formativa`,
          icon: "fas fa-graduation-cap",
          claims: ['OFFERTA_FORMATIVA.POF.enabled']
        },
        props: true
      }],
    },
    {
      name: 'enti',
      path: "/enti",
      component: AuthorizedLayout,
      redirect: "/enti/etf",
      meta: {
        title: "Enti",
        icon: "fab fa-digital-ocean",
        claims: ['ANAGRAFICA_ENTI.viewETF', 'ANAGRAFICA_ENTI.viewAziende']
      },
      children: [{
        name: 'etf',
        path: '/enti/etf',
        component: () => import(/* webpackChunkName: "enti-aziende" */ '@/modules/enti/views/etf.vue'),
        meta: {
          title: `Enti terzi formatori`,
          icon: "fab fa-digital-ocean",
          claims: ['ANAGRAFICA_ENTI.viewETF']
        }
      },
      {
        name: 'aziende',
        path: '/enti/aziende',
        component: () => import(/* webpackChunkName: "enti-aziende" */ '@/modules/enti/views/aziende.vue'),
        meta: {
          title: `Aziende`,
          icon: "fab fa-digital-ocean",
          claims: ['ANAGRAFICA_ENTI.viewAziende']
        }
      }],
    },
    {
      name: "organi",
      path: "/organi",
      component: AuthorizedLayout,
      redirect: "/organi/crog",
      meta: {
        title: "Organi",
        icon: "el-icon-menu",
        claims: ['ANAGRAFICA_ENTI.viewCTS', 'ANAGRAFICA_ENTI.viewCNOG', 'ANAGRAFICA_ENTI.viewCROG']
      },
      children: [{
        name: 'crog',
        path: '/organi/crog',
        component: () => import(/* webpackChunkName: "enti-crog" */ '@/modules/enti/views/crog.vue'),
        meta: {
          title: `CROG`,
          icon: "el-icon-menu",
          claims: ['ANAGRAFICA_ENTI.viewCROG']
        }
      },
      {
        name: 'cnog',
        path: '/organi/cnog',
        component: () => import(/* webpackChunkName: "enti-aziende" */ '@/modules/enti/views/cnog.vue'),
        meta: {
          title: `CNOG`,
          icon: "el-icon-menu",
          claims: ['ANAGRAFICA_ENTI.viewCNOG']
        }
      },
      {
        name: 'cts',
        path: '/organi/cts',
        component: () => import(/* webpackChunkName: "enti-aziende" */ '@/modules/enti/views/cts.vue'),
        meta: {
          title: `CTS`,
          icon: "el-icon-menu",
          claims: ['ANAGRAFICA_ENTI.viewCTS']
        }
      }],
    },
    {
      name: "richieste",
      path: "/richieste",
      component: AuthorizedLayout,
      redirect: "/richieste/speciali",
      meta: {
        icon: "fas fa-life-ring",
        claims: ['RICHIESTE_SPECIALI.enabled']
      },
      children: [{
        name: 'speciali',
        path: '/richieste/speciali',
        component: () => import(/* webpackChunkName: "user" */ '@/views/richiesteSpeciali/index.vue'),
        meta: {
          title: `Richieste Speciali`,
          icon: "fas fa-life-ring"
        }
      }],
    },
    {
      name: "impostazioni",
      path: "/impostazioni",
      component: AuthorizedLayout,
      redirect: "/impostazioni/utenti",
      meta: {
        title: "Impostazioni",
        icon: "el-icon-setting",
        claims: ['USERS.enabled', 'GROUPS.enabled', 'USERS.viewOrg']
      },
      children: [{
        name: 'utenti',
        path: '/impostazioni/utenti',
        component: () => import(/* webpackChunkName: "user" */ '@/modules/users/views/index.vue'),
        meta: {
          title: `Utenti`,
          icon: "fas fa-users",
          claims: ['USERS.enabled', 'USERS.viewOrg']
        }
      },
      {
        name: 'ruoli',
        path: '/impostazioni/ruoli',
        component: () => import(/* webpackChunkName: "roles" */ '@/modules/roles/views/rolesView.vue'),
        meta: {
          title: `Ruoli`,
          icon: "fas fa-key",
          claims: ['GROUPS.enabled']
        }
      }],
    },
    {
      name: 'timeline',
      path: '/timeline',
      component: AuthorizedLayout,
      redirect: '/timeline/logs',
      meta: {
        title: 'Timeline',
        icon: 'fas fa-clock',
        claims: ['TIMELINE.enabled']
      },
      children: [
        {
          name: 'logs',
          path: '/timeline/logs',
          component: () => import(/* webpackChunkName: "logs" */ '@/modules/logs/views/logs.vue'),
          meta: {
            title: 'Timeline operazioni',
            icon: 'fas fa-clock',
            claims: ['TIMELINE.enabled']
          }
        },
        {
          name: "logDetail",
          path: "/timeline/logs/:id",
          component: () => import(/* webpackChunkName: "logs" */ '@/modules/logs/views/log.vue'),
          meta: {
            title: `Dettaglio operazione`,
            hidden: true
          },
          props: true,
        },
        {
          name: 'logs',
          path: '/timeline/console-logs',
          component: () => import(/* webpackChunkName: "logs" */ '@/modules/logs/views/consoleLogs.vue'),
          meta: {
            title: 'Timeline console',
            icon: 'fas fa-clock',
            claims: ['TIMELINE.console']
          }
        }
      ]
    }
  ]
}

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes()
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
