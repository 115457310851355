import axios from "axios";

declare const PRODUCTION;

interface IAuthSettings {
  clientId: string
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}

interface IAppSettings {
  allowedAncestorOrigins: string[],
  obiettivoFormativo: {
    default: {
      totale: number,
      deontologici: number,
    },
    anni30daPrimaIscrizione: {
      totale: number,
      deontologici: number,
    }
  },
  corsi: {
    numeroPartecipanti: {
      min: number,
      max: number
    },
    maxDurata: {
      webinar: {
        quantitaOre: number
      }
    },
    dataFineIscrizioni: {
      quantitaGiorni: number
    },
    prelazioneOrdineRegionale: {
      intervalloDiPrelazioneInOre: number
    }
  },
  creditiSpeciali: {
    tipoFormazione: Object,
  },
  blacklist:{
    assenzePerIngresso: number
    durataInGiorni: number
  }
  richiesteSpeciali: { title: string, value: string, formKey: string }[]
}

interface ISettings {
  baseUrl: string,
  connectAuthorizeMoodleUrl?: string,
  authSettings: IAuthSettings
  appSetting: IAppSettings
}

// You can customize settings below :)
const settings: ISettings = {
  baseUrl: 'https://localhost:5000',
  connectAuthorizeMoodleUrl: "https://staging-api.formazionegiornalisti.it/connect/authorize?client_id=moodle&response_type=code&redirect_uri=https%3A%2F%2Fmoodleodgtest.odg.it%2Fadmin%2Foauth2callback.php",
  authSettings: {
    clientId: 'cnog',
    scopes: 'profile email roles offline_access',
    endpoint: 'https://localhost:5000',
    refreshTokenInterval: 5
  },
  appSetting: {
    allowedAncestorOrigins: [],
    obiettivoFormativo: {
      default: {
        totale: 60,
        deontologici: 20,
      },
      anni30daPrimaIscrizione: {
        totale: 20,
        deontologici: 10,
      }
    },
    corsi: {
      numeroPartecipanti: {
        min: 20,
        max: 50
      },
      dataFineIscrizioni: {
        quantitaGiorni: 1
      },
      maxDurata: {
        webinar: {
          quantitaOre: 3
        }
      },
      prelazioneOrdineRegionale: {
        intervalloDiPrelazioneInOre: 48
      }
    },
    creditiSpeciali: {
      tipoFormazione: {
        Autoformazione: 6,
        Docenza: 16
      }
    },
    blacklist: {
      assenzePerIngresso: 3,
      durataInGiorni: 90
    },
    richiesteSpeciali: []
  }
}


export async function loadSettings() {

  let response = null;

  try {
    response = await axios.get("/settings.custom.json");
    Object.assign(settings, response.data);
  } catch { }

  if (!response)
    try {
      response = await axios.get("/settings.json");
      Object.assign(settings, response.data);
    } catch { }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }

}



export default settings
