import { compile } from 'path-to-regexp';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteRecord, Route } from 'vue-router';

@Component({})
export default class InfoFilterBox extends Vue {
  @Prop({ default: "Clicca sull'icona per ricercare e visualizzare i risultati più adatti alle tue esigenze", required: false })
  text: string;

  visible: boolean = false;
  mounted() {
    setTimeout(() => {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 10000)
    }, 1000)
  }

  beforeDestroy() {
    this.visible = false
  }
}