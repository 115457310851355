export default {
  giornalista: {
    creditimaturati: { warninglabel: "Il processo di allineamento dei dati è in corso. Nei prossimi giorni i crediti risulteranno aggiornati." },
    corsi: { warninglabel: "Il processo di allineamento dei dati è in corso. Nei prossimi giorni i corsi risulteranno aggiornati." },
    creditispeciali: { warninglabel: "I crediti speciali sono gia conteggiati nei crediti maturati. Viene riportato il dettaglio dei soli Crediti Speciali maturati a partire dall'anno 2022." }
  },
  route: {
    dashboard: 'Dashboard',
    corsi: 'Corsi',
    anagrafica: 'Anagrafica',
    enti: 'Enti',
    utenti: 'Utenti',
    ruoli: 'Ruoli',
    offertaFormativa: 'Offerta Formativa'
  },
  login: {
    title: `Accesso all'account`,
    logIn: `Accedi`,
    register: `Registrati`,
    username: `Codice fiscale`,
    password: `Password`,
    capsTooltip: `Blocco maiuscolo attivo`,
    recoverPassword: `Hai dimenticato la password?`,
    // error: `Tentativo di accesso non valido.<br/><br/> Gentile utente, se non hai ancora confermato l'account, cerca nella posta elettronica una email con l'oggetto <b>"ODG Formazione Professionale Continua - Conferma l'account"</b>
    // e segui le istruzioni che trovi al suo interno.<br/><br/>In caso contrario, compila il modulo inserendo le stesse credenziali che hai usato per registrare l'account la prima volta.<br/> 
    // <br/> Non hai ricevuto nessuna email ? <br/> Controlla le FAQ nel portale del supporto per risolvere il problema. <a href='https://cnog.teamdev.it/'>SUPPORTO</> `,
    error: `<div style="font-size: 1.1rem; display: flex; flex-flow: column nowrap; gap: 1rem">
              <p style="line-height: 1.6rem;"><b>Nome utente o password non corretti</b></p>
              <p style="line-height: 1.6rem;">Si ricorda che per l'accesso come giornalista il nome utente risulta essere il proprio codice fiscale.</p>
              <p style="line-height: 1.6rem;">Hai dimenticato la password? <a href="/recoverPassword">Clicca qui</a></p>
          </div>`,
    required: {
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      username: `il campo Username è obbligatorio`,
      password: `il campo Password è obbligatorio`
    }
  },
  addCredential: {
    title: `Aggiunge credenziali di accesso`,
    passwordPlaceholder: `Inserisci la nuova password`,
    confirmPasswordPlaceholder: `Ripeti la nuova password`,
    send: `Imposta la password`,
    backToLogin: `Torna al pannello di accesso`,
    success: `La password è stata cambiata con successo`,
    successRedirect: `Accedi al servizio utilizzando le nuove credenziali impostate`,
    error: `Si è verificato un errore nel cambio password dell'account`,
    required: {
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      password: `il campo Password è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  changeCredential: {
    title: `Cambia credenziali di accesso`,
    warningMessage: `La password utilizzata è quella fornita dall'amministrazione. E' necessario cambiare la password impostando una nuova password personale. Al cambio password l'utente verrà riportato alla pagina di login in modo da poter accedere con le nuove credenziali.`,
    defaultPasswordPlaceholder: `Inserisci la password di default`,
    newPasswordPlaceholder: `Inserisci la nuova password`,
    confirmPasswordPlaceholder: `Ripeti la nuova password`,
    send: `Cambia la password`,
    backToLogin: `Torna al pannello di accesso`,
    success: `La password è stata cambiata con successo`,
    successRedirect: `Accedi al servizio utilizzando le nuove credenziali impostate`,
    error: `Si è verificato un errore nel cambio password dell'account`,
    required: {
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      defaultPassword: `il campo 'Passord di default' è obbligatorio`,
      newPassword: `il campo 'Nuova password' è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  blacklist: {
    title: `Assenze ingiustificate`,
    infoMessage: `Le assenze ingiustificate vengono conteggiate ai fini dell'inserimento in black list.<br>L'inserimento in black list fa sì che non si potranno piu prenotare corsi di aggiornamento per un periodo di 90 giorni dalla data di conferma della terza assenza, inoltre nel medesimo periodo saranno cancellate le eventuali iscrizioni già prenotate`,
    warningMessage: `Attenzione! Alla conferma della terza assenza ingiustificata si verrà inseriti in black list e non si potranno più prenotare corsi di aggiornamento per un periodo di 90 giorni dalla data di conferma della terza assenza`,
    alertMessage: `Attenzione! Il giornalista {giornalista} è in black list. Non sarà possibile prenotare corsi di aggiornamento fino al {data}`,
    required: {
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      defaultPassword: `il campo 'Passord di default' è obbligatorio`,
      newPassword: `il campo 'Nuova password' è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  emailConfirm: {
    title: `Conferma indirizzo E-Mail`,
    validationInProgress: `Validazione in corso...`,
    success: `L'account associato alla e-mail è stato confermato con successo.`,
    error: `Si è verificato un problema con l'attivazione dell'account`,
    backToLogin: `Torna al pannello di accesso`,
  },
  register: {
    title: `Completa la registrazione per accedere alla formazione`,
    userName: `Codice Fiscale`,
    nome: `Nome`,
    cognome: `Cognome`,
    password: `Password`,
    email: `E-Mail`,
    phoneNumber: `Numero di telefono`,
    passwordPlaceholder: `Inserisci la nuova password`,
    confirmPasswordPlaceholder: `Ripeti la nuova password`,
    confirm: `Registrati`,
    cancel: `Annulla`,
    welcome: `Benvenuto, {value}`,
    success: `<strong>{value}</strong> la registrazione del tuo account è avvenuto con successo.<br/> Ti abbiamo inviato una mail per verificare il tuo account. <br/> Devi confermare il link che trovi nella mail che ti abbiamo inviato.</br><b> ATTENZIONE: il link scade dopo 24 ore!</b><br/> Conferma la tua email prima che scada!`,
    successRedirectTitle: `Account registrato con successo`,
    successRedirect: `Accedi all'area privata!`,
    successRedirectBtn: `Vai alla Dashboard`,
    successRedirectWhenLockoutEnabled: `Il tuo account non è ancora attivo. Attendi l'e-mail di avvenuta attivazione per poter accedere.`,
    successRedirectWhenLockoutEnabledBtn: `Torna alla Home`,
    error: `Si è verificato un errore nella registrazione del tuo account`,
    required: {
      userName: `il campo Codice Fiscale è obbligatorio`,
      userNameRequired: `Inserisci un valore nel campo Codice Fiscale`,
      userNameExsist: `E' già presente un utente con questo Codice Fiscale, se non ricordi la password segui la procedura di recupero password.`,
      email: `il campo E-Mail è obbligatorio`,
      emailRequired: `Inserisci un valore nel campo E-mail`,
      emailPattern: `l'indirizzo E-Mail inserito non è valido`,
      emailExsist: `E' già presente un utente con questa Email`,
      nome: `il campo Nome è obbligatorio`,
      cognome: `il campo Cognome è obbligatorio`,
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      password: `il campo Password è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  recoverPassword: {
    title: `Recupera password di accesso`,
    info: `Hai dimenticato la password del tuo account o hai problemi ad accedere al tuo account? Inserisci il tuo nome utente o il tuo codice fiscale e ti invieremo un link per il recupero.`,
    username: `Username o Codice Fiscale`,
    send: `Invia E-Mail di recupero`,
    backToLogin: `Torna al pannello di accesso`,
    noResetPasswordForInternalUser: `Non è possibile richiedere il reset password per l'utente interno. Contattare l'amministratore`,
    noUserFound: `Non è presente un account registrato con questa Nome utente`,
    success: `Richiesta di recupero password inoltrata con successo`,
    successRedirect: `Controlla la tua e-mail e concludi la procedura di recupero password.`,
    error: `Si è verificato un errore nella richiesta di recupero password`,
    required: {
      username: `il campo Username o Codice Fiscale è obbligatorio`,
      usernameRequired: `Inserisci un valore nel campo Username o Codice Fiscale`
    }
  },
  resetPassword: {
    title: `Reset password`,
    passwordPlaceholder: `Inserisci la nuova password`,
    confirmPasswordPlaceholder: `Ripeti la nuova password`,
    send: `Resetta la password`,
    backToLogin: `Torna al pannello di accesso`,
    success: `La password è stata cambiata con successo`,
    successRedirect: `Accedi al servizio utilizzando le nuove credenziali impostate`,
    error: `Si è verificato un errore nel cambio password dell'account`,
    required: {
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      password: `il campo Password è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  changePassword: {
    title: `Cambia password`,
    warningMessage: `Al cambio password, l'utente verrà riportato alla pagina di login in modo da poter accedere con le nuove credenziali`,
    oldPassword: `Vecchia password`,
    newPassword: `Nuova password`,
    confirmPassword: `Conferma password`,
    oldPasswordPlaceholder: `Inserisci la vecchia password`,
    newPasswordPlaceholder: `Inserisci la nuova password`,
    confirmPasswordPlaceholder: `Ripeti la nuova password`,
    confirm: `Cambia la password`,
    success: `La password è stata cambiata con successo`,
    successRedirect: `Accedi al servizio utilizzando le nuove credenziali impostate`,
    error: `Si è verificato un errore nel cambio password dell'account`,
    required: {
      password: `il campo Password è obbligatorio`,
      passwordPattern: `La password deve essere di almeno 8 caratteri contenenti almeno una lettera minuscola, almeno una lettera maiuscola, almeno un valore numerico, almeno un carattere tra i seguenti #$^/+=_!*()@%&-`,
      newPassword: `il campo 'Nuova password' è obbligatorio`,
      confirmPassword: `Conferma la nuova password`,
      confirmPasswordMatch: `Le due password non corrispondono`,
    }
  },
  profile: {
    title: 'Profilo utente',
    userName: `Username`,
    nome: `Nome`,
    cognome: `Cognome`,
    email: `E-Mail`,
    phoneNumber: `Telefono`,
    note: `Note`,
    confirm: `Aggiorna`,
    cancel: `Annulla`,
    tabChangeAttributes: 'Gestisci utente di formazionegiornalisti.it',
    tabChangeCredential: 'Cambia le credenziali di accesso',
    tabCrediti: 'Crediti Maturati',
    success: `Le informazioni del profilo sono state aggiornate con successo`,
    error: `Si è verificato un errore nell'aggiornamento del profilo`,
    required: {
      firstName: `il campo Nome è obbligatorio`,
      lastName: `il campo Cognome è obbligatorio`
    }
  },
  navbar: {
    logOut: 'Esci',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Tema',
    size: 'Grandezza Globale',
    profile: 'Profilo'
  },
  creditiSpeciali: {
    stati: {
      proposto: 'Proposto',
      approvato: 'Approvato',
      rifiutato: 'Rifiutato',
    },
    tipi: {
      autoformazione: 'Autoformazione',
      docenza: 'Docenza',
    },
    dialog: {
      check: {
        title: "Controlla i Crediti Speciali rimanenti di un/una Giornalista",
        searchLabel: "Ricerca un/una giornalista",
        noResults: "Nessun dato / in attesa di una ricerca",
      },
    },
  },
  corso: {
    stati: {
      inCompilazione: 'In Compilazione',
      proposto: 'Proposto',
      approvato: 'Approvato',
      rifiutato: 'Rifiutato',
      pubblicato: 'Pubblicato',
      inErogazione: 'In Erogazione',
      sospeso: 'Sospeso',
      annullato: 'Annullato',
      obsoleto: 'Obsoleto',
      chiuso: 'Chiuso',
    },
    tabs: {
      programma: 'Programma',
      relatori: 'Relatori',
      moduli: 'Moduli',
      rilevatori: 'Rilevatori',
    },
    titolo: 'Titolo',
    programma: 'Programma',
    categoriaAutocomplete: 'Categorie',
    numeroMinimoPartecipanti: 'Numero minimo partecipanti',
    numeroMassimoPartecipanti: 'Numero massimo partecipanti',
    creditiDisponibili: 'Crediti disponibili',
    costo: 'Costo',
    referente: "Referente",
    referenteAziendale: "Referente aziendale",
    autocompleteReferente: 'Referenti',
    corsoAziendale: 'Corso aziendale',
    autoformazione: 'Autoformazione',
    data: 'Data',
    autocomplete: 'Ricerca',
    dataPlaceholder: 'Scegli una data',
    insertFailed: "Errore durante l'inserimento",
    argomento: {
      nuovoArgomento: "+ nuovo argomento"
    }
  },
  users: {
    search: 'Cerca',
    accountLabel: 'Account:',
    createUserTitle: 'Inserisci utente',
    createUserLogin: 'Login',
    createUserLoginTip: 'Inserisci Login',
    createUserEmail: 'Email',
    createUserPhone: 'Numero di telefono',
    createUserFiscalCode: 'Codice Fiscale',
    userExistsTitle: 'Attenzione',
    userExistsBody: 'Utente Esistente',
    updateUserButton: 'Aggiorna',
    removeUserButton: 'Elimina',
  },
  anagrafica: {
    giornalisti: {
      stati: {
        abilitato: "Abilitato/a",
        rinuncia: "Ritirato/a",
        cancellato: "Cancellato/a",
        pensionato: "In pensione",
        deceduto: "Deceduto/a",
      },
      categorie: {
        professionita: "Professionista",
        pubblicista: "Pubblicista",
        speciale: "Speciale",
      },
      categoria: {
        PRO: "Professionista",
        PUB: "Pubblicista",
        PUB_ABI: "Pubblicista",
        PRA: "Praticante",
        PRA_PUB: "Pubblicista",
        SPE: "Speciale",
        STRA: "Stranieri"
      }
    },
  },
  timeline: {
    text: `Testo da ricercare`,
    user: `Utente`,
    type: `Tipo`,
    ipAddress: `Indirizzo Ip`,
    operation: `Operazione`,
    message: `Messaggio`,
    action: `Azione`,
    context: `Contesto`,
    username: `Username`,
    date: `Data`,
    types: {
      unclassified: `Non classificato`,
      success: `Successo`,
      warning: `Allerta`,
      error: `Errore`,
      info: `Info`,
      exception: `Eccezione`
    },
    filter: {
      sort: {
        type: `Ordina per Tipo`,
        user: `Ordina per Nome utente`,
        date: `Ordina per Data di Creaizone`
      },
      byText: `Ricerca nel Testo`,
      byUser: `Filtra per Utente`,
      byType: `Filtra per Tipo`
    },
    table: {
      loading: 'Caricamento timeline in corso...',
      emptyText: 'Nessun log trovato',
      emptyFilter: 'Nessun dato corrisponde ai filtri di ricerca impostati',
    }
  },
  enti: {
    tipo: {
      cts: 'Comitato Tecnico Scentifico',
      cnog: 'Consiglio Nazionale Ordine',
      crog: 'Consiglio Regionale Ordine',
      etf: 'Ente Terzo Formatore',
      azienda: 'Azienda'
    }
  },
  errorPage: {
    back: `Torna alla Home`,
    401: {
      title: `Non sei autorizzato ad accedere`,
      description: `Siamo spiacenti, l'accesso alla pagina richiesta con le sue credenziali è stato negato.`
    },
    404: {
      title: `Pagina non trovata`,
      description: `Siamo spiacenti, ma non è stato possibile trovare la pagina richiesta.`
    }
  },
  claims: {
    ANAGRAFICA_ENTI: {
      __groupTitle: 'Anagrafica Enti, Organi',
      canCreateAziende: 'Aggiunge Azienda',
      canCreateCROG: 'Aggiunge CROG',
      canCreateETF: 'Aggiunge ETF',
      canDeleteAziende: 'Cancella Azienda',
      canDeleteCROG: 'Cancella CROG',
      canDeleteETF: 'Cancella ETF',
      canEditAziende: 'Modifica Azienda',
      canEditCROG: 'Modifica CROG',
      canEditETF: 'Modifica ETF',
      viewAziende: 'Visualizza Aziende',
      viewCNOG: 'Visualizza CNOG',
      viewCROG: 'Visualizza CROG',
      viewCTS: 'Visualizza CTS',
      viewETF: 'Visualizza ETF',
      canRemoveFromBlacklist: "Rimuove giornalista da blacklist",
      canExportGiornalistiODG: "Esporta elenco giornalisti ODG",
      canExportGiornalistiODGRegionale: "Esporta elenco giornalisti ODG Regionale"
    },
    ANAGRAFICA_GIORNALISTI: {
      __groupTitle: 'Anagrafica Giornalisti',
      canAddEsenzioni: 'Aggiunge Esenzione',
      canAddEsenzioniMassiva: 'Aggiungi Esenzioni Massiva',
      canShowDetail: 'Visalizza Dettaglio',
      viewAll: 'Visualizza tutti, non filtrati per categoria',
      viewAllDates: 'Visualizza tutti, non filtrati per date',
      viewOrg: 'Visualizza quelli dell\'ente di appartenenza',
    },
    CORSO: {
      __groupTitle: 'Gestione Corsi',
      canClone: 'Duplica Corso',
      canCreate: 'Aggiunge Corso',
      canDelete: 'Cancella Corso',
      canEdit: 'Modifica Corso',
      setReferente: 'Imposta Referente',
      setUpReporterAsSpeaker: 'Imposta giornalista come relatore',
      viewAll: 'Visualizza tutti',
      viewOrg: 'Visualizza quelli dell\'ente di appartenenza',
      viewMine: 'Visualizza quelli dell\'utente corrente',
      viewOnlyRilevatore: 'Visualizza quelli di cui si è Rilevatore',
      forcedImportPartecipanti: 'Importa i partecipanti indipendentemente dallo stato del Corso',
      canSetStato: {
        annullato: 'Imposta stato "annullato"',
        approvato: 'Imposta stato "approvato"',
        chiuso: 'Imposta stato "chiuso"',
        inCompilazione: 'Imposta stato "inCompilazione"',
        inErogazione: 'Imposta stato "inErogazione"',
        obsoleto: 'Imposta stato "obsoleto"',
        proposto: 'Imposta stato "proposto"',
        pubblicato: 'Imposta stato "pubblicato"',
        rifiutato: 'Imposta stato "rifiutato"',
        sospeso: 'Imposta stato "sospeso"',
      }
    },
    CREDSPECIALI: {
      __groupTitle: 'Gestione Crediti Speciali',
      canCreate: 'Richiesta Crediti Speciali',
      canEdit: 'Modifica Richiesta Crediti Speciali',
      viewAll: 'Mostra tutti i Crediti Speciali',
      viewOrg: 'Mostra i Crediti Speciali nell\'ambito dell\'Ente',
      viewMine: 'Visualizzo i miei Crediti Speciali',
      canValutare: 'Può valutare richiesta di Crediti Speciali',
      canSetStato: {
        proposto: 'Imposta stato "Proposto"',
      }
    },
    GIORNALISTI: {
      __groupTitle: 'Corsi Giornalista',
      corsiAperti: 'Visualizza "Corsi Disponibili"',
      iMieiCorsi: 'Visualizza "I Miei Corsi"',
      corsiOnline: 'Visualizza il link ai "Corsi Online"',
      viewCorsiInAnagrafica: `Visualizza "Tutti i Corsi nell'anagrafica"`,
      viewCreditiSpecialiInAnagrafica: `Visualizza "Crediti Speciali nell'anagrafica"`,
      viewPeriodoFormativoInCorsiEffettuati: `Visualizza il Periodo Formativo nei Corsi Effettuati`
    },
    GROUPS: {
      __groupTitle: 'Gestione Ruoli e Permessi',
      canaddusers: 'Aggiunge utente utente al ruolo',
      cancreate: 'Aggiunge Ruolo',
      candelete: 'Cancella Ruolo',
      canedit: 'Modifica Ruolo',
      canremoveusers: 'Cancella utente associato al ruolo',
      canseeothergroups: 'Può vedere altri gruppi',
      enabled: 'Visualizza l\'elenco dei ruoli',
    },
    OFFERTA_FORMATIVA: {
      __groupTitle: 'Gestione Offerta formativa',
      PF: {
        canCreate: 'Aggiunge Periodo Formativo',
        canDelete: 'Cancella Periodo Formativo',
        canEdit: 'Modifica Periodo Formativo',
        enabled: 'Visualizza Periodi Formativi',
        canSetStato: {
          inCompilazione: 'Imposta stato "inCompilazione"',
          pubblicato: 'Imposta stato "pubblicato"',
          corrente: 'Imposta stato "corrente"',
          chiuso: 'Imposta stato "chiuso"'
        }
      },
      POF: {
        canCreate: 'Aggiunge Piano Offerta Formativa',
        canDelete: 'Cancella Piano Offerta Formativa',
        canEdit: 'Modifica Piano Offerta Formativa',
        enabled: 'Visualizza Offerte Formative',
      }
    },
    CREDITI_GIORNALISTA: {
      __groupTitle: 'Crediti Giornalista',
      ricalcolaAdempienzaGiornalista: 'Ricalcola adempienza giornalista',
      ricalcolaAdempienzePeriodoFormativo: 'Ricalcola adempienze giornalisti per un periodo formativo'
    },
    TIMELINE: {
      __groupTitle: 'Gestione Timeline operazioni',
      enabled: 'Visualizza la Timeline'
    },
    USERS: {
      __groupTitle: 'Gestione Utenze',
      cancreate: 'Aggiunge Utente',
      candelete: 'Cancella Utente',
      canedit: 'Modifica Utente',
      enabled: 'Visualizza l\'elenco degli utenti',
      canAssignGiornalista: 'Assegna un anagrafica giornalista',
      viewOrg: 'Visualizza l\'elenco degli utenti dell\'ente di appartenenza'
    },
    RICHIESTE_SPECIALI: {
      __groupTitle: 'Richieste Speciali',
      enabled: 'Ha facolta di inviare Richeste Speciali'
    }
  },
  esenzioni: {
    anni30daPrimaIscrizione: `perché sono decorsi 30 anni dalla prima iscrizione all'albo avvenuta il {dataPrimaIscrizione}. Lista completa delle esenzioni nel tab "Esenzioni"`,
    pensione: `perché la/il giornalista è in pensione o ha raggiunto i 67 anni di età. Lista completa delle esenzioni nel tab "Esenzioni"`
  },
  periodoFormativo: {
    stati: {
      inCompilazione: 'In Compilazione',
      pubblicato: 'Pubblicato',
      corrente: 'Corrente',
      chiuso: 'Chiuso',
      prorogato: 'Prorogato',
    }
  }
}
