/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function validatePassword(str) {
    // const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^/+=_!*()@%&-]).{6,}$/g;
    // return reg.test(str);
    let isValid: boolean = false;
    let result = {
        length: str.length >= 8,
        digit: /\d/.test(str),
        lowercase: /[a-z]/.test(str),
        uppercase: /[A-Z]/.test(str),
        special: /[^a-zA-Z0-9]/.test(str),
    };

    isValid = result.digit && result.length && result.lowercase && result.special && result.uppercase;
    return isValid;
}

export function valdiateEmail(str) {
    const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return reg.test(str);
}

export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return urlregex.test(textval);
}

export function validateItalianFiscalCode(textval) {
    const reg = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9A-Za-z]{3}[A-Za-z]{1}$/
    return reg.test(textval);
}

export function validateItalianPartitaIvaCode(textval) {
    const reg = /^[0-9]{11}$/;
    return reg.test(textval);
}
