import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";
import { store } from "@/store";
import { periodiFormativiStore } from "../store/store";

class PeriodoFormativoService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = () => `${settings.baseUrl}/api`;
    }

    async Count(params?: periodoFormativo.queryParams): Promise<number> {
        return await this.Get(`/periodo-formativo/count`, params);
    }

    async List(params?: periodoFormativo.queryParams): Promise<periodoFormativo.item[]> {
        var result = await this.Get<periodoFormativo.item[]>("/periodo-formativo", params);
        if (!params) store.actions.periodiFormativi.setList(result);
        return result;
    }

    async Current(): Promise<periodoFormativo.item> {
        var result = await this.Get<periodoFormativo.item>(`/periodo-formativo/current`);
        store.actions.periodiFormativi.setCurrent(result);
        return result;
    }

    async Detail(id: string): Promise<periodoFormativo.item> {
        return await this.Get(`/periodo-formativo/${id}`);
    }

    async Exist(item: periodoFormativo.exist): Promise<boolean> {
        return await this.Post('/periodo-formativo/exists', item);
    }

    async Set(item: periodoFormativo.item): Promise<periodoFormativo.item> {
        return await this.Post('/periodo-formativo', item);
    }

    async Remove(id: string) {
        return await this.Delete(`/periodo-formativo/${id}`);
    }

    async GetByDate(data: Date): Promise<periodoFormativo.item[]> {
        return await this.Post('/periodo-formativo/getByDate', data);
    }

    async ChangeStatoToPubblicato(id: string) {
        return await this.Put<periodoFormativo.item>(`/periodo-formativo/change-stato/pubblicato/${id}`, {});
    }

    async ChangeStatoToCorrente(nuovoCorrenteId: string, vecchioCorrenteId: string) {
        return await this.Put<periodoFormativo.item[]>(`/periodo-formativo/change-stato/corrente/${nuovoCorrenteId}/${vecchioCorrenteId}`, {});
    }
}

export const periodoFormativoService = new PeriodoFormativoService();