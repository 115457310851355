import { isExternal } from '@/utils/validate';
import path from 'path';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';

import SidebarLink from './sidebar-link.vue';

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarLink
  }
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isNest!: boolean;
  @Prop({ default: '' }) private basePath!: string;

  onlyOneChild: RouteConfig = null;
  
  hasOneShowingChild(children = [], parent) {
    const showingChildren = children.filter((item: RouteConfig) => {
      if (item.meta.hidden) {
        return false
      } else {
        // Temp set(will be used if only has one showing child)
        this.onlyOneChild = item
        return true
      }
    })
    // When there is only one child router, the child router is displayed by default
    if (showingChildren.length === 1) {
      return true
    }
    // Show parent if there are no child router to display
    if (showingChildren.length === 0) {
      this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
      return true
    }
    return false
  }

  resolvePath(routePath) {
    if (isExternal(routePath)) {
      return routePath
    }
    if (isExternal(this.basePath)) {
      return this.basePath
    }
    const routePathWithoutParameter = routePath.includes('/:') ? routePath.split('/:')[0] : routePath;
    return path.resolve(this.basePath, routePathWithoutParameter);
  }
}
