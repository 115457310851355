import Vue from 'vue'
import Vuex from 'vuex'
import { appStore, IAppState, IAppStoreActions, IAppStoreGetters } from "./modules/app";
import { loginStore, LoginStoreActions, LoginStoreGetters, LoginStoreModel } from "./modules/login.store";
import { permissionStore, PermissionStoreActions, PermissionStoreGetters, PermissionStoreModel } from './modules/permission.store';
import { corsiStore, ICorsiActions, ICorsiState } from "@/modules/corsi/store/store";
import { corsiApertiStore, ICorsiApertiActions, ICorsiApertiState } from "@/modules/giornalisti/components/store/store";

import { CreateTypedStoreProxy } from "vuex-typed-store";
import { IPeriodiFormativiActions, IPeriodiFormativiGetters, IPeriodiFormativiState, periodiFormativiStore } from '@/modules/offertaFormativa/store/store';

Vue.use(Vuex)

export interface IRootState {
  app: IAppState,
  login: LoginStoreModel,
  permission: PermissionStoreModel,
  corsi: ICorsiState,
  corsiAperti: ICorsiApertiState,
  periodiFormativi: IPeriodiFormativiState,
}

export interface IRootActions {
  app: IAppStoreActions,
  login: LoginStoreActions,
  permission: PermissionStoreActions, 
  corsi: ICorsiActions,
  corsiAperti: ICorsiApertiActions,
  periodiFormativi: IPeriodiFormativiActions,
}

export interface IRootGetters {
  app: IAppStoreGetters
  login: LoginStoreGetters
  permission: PermissionStoreGetters,
  periodiFormativi: IPeriodiFormativiGetters,
}

// Declare empty store first, dynamically register all modules later.
export const vueStore = new Vuex.Store({
  modules: {
    [appStore.PREFIX]: appStore,
    [loginStore.PREFIX]: loginStore,
    [permissionStore.PREFIX]: permissionStore,
    [corsiStore.PREFIX]: corsiStore,
    [corsiApertiStore.PREFIX]: corsiApertiStore,
    [periodiFormativiStore.PREFIX]: periodiFormativiStore,
  }
})

export const store = CreateTypedStoreProxy<
  IRootState,
  IRootActions,
  IRootGetters
>(vueStore);


