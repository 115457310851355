import { stat } from "fs";
import { GetterTree } from "vuex";

export interface IPeriodiFormativiState {
  list: periodoFormativo.item[];
  current: periodoFormativo.item;
}

export interface IPeriodiFormativiActions {
  setList(periodiFormativi: periodoFormativo.item[]);
  setCurrent(periodoFormativo: periodoFormativo.item);
}

export interface IPeriodiFormativiGetters {
  list: () => periodoFormativo.item[]
  current: () => periodoFormativo.item;
}

export const periodiFormativiStore = {
  PREFIX: "periodiFormativi",
  namespaced: true,
  state: {
    list: [],
    current: null,
  } as IPeriodiFormativiState,
  getters: {
    list: (state: IPeriodiFormativiState) => (): periodoFormativo.item[] => state.list,
    current: (state: IPeriodiFormativiState) => (): periodoFormativo.item => state.current,
    inProroga: (state: IPeriodiFormativiState) => (): periodoFormativo.item[] => state.list.filter(f => f.stato === 'prorogato'),
  },
  mutations: {
    SET_CURRENT(state: IPeriodiFormativiState, model: periodoFormativo.item) {
      state.current = model;
    },
    SET_LIST(state: IPeriodiFormativiState, model: periodoFormativo.item[]) {
      state.list.splice(0)
      for (const element of model) {
        state.list.push(element);
      }
    }
  },
  actions: {
    setCurrent({ commit }, model: periodoFormativo.item) {
      commit("SET_CURRENT", model)
    },
    setList({ commit }, model: periodoFormativo.item[]) {
      commit("SET_LIST", model);
      if (model.length > 0 && model.find(f => f.stato === 'corrente')) {
        commit("SET_CURRENT", model.find(f => f.stato === 'corrente'));
      }
    }
  }
}
