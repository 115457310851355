import { Component, Vue } from 'vue-property-decorator';
import SidebarItem from './components/sidebar-item.vue';
import SidebarLogo from './components/sidebar-logo.vue';
import { store } from '@/store';

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    SidebarLogo
  }
})
export default class extends Vue {
  get sidebar() {
    return store.state.app.sidebar;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }

  get routes() {
    return store.getters.permission.routes();
  }

  get activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    if (meta.activeMenu)
      return meta.activeMenu;
    return path;
  }
}
