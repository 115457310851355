import { Vue, Component } from 'vue-property-decorator';
import { authService } from '@/modules/account/services/authService';
import { store } from '@/store';

import md5 from 'md5';

@Component({
  name: 'NavbarUserProfile'
})
export default class extends Vue {
  get currentUser() {
    return store.getters.login.userInfo()?.userName;
  }

  private async logout() {
    await authService.Logout()
    location.reload();
    // this.$router.push(`/login?redirect=${this.$route.fullPath}`).catch(err => {
    //   console.warn(err)
    // })
  }
}
