import { Component, Vue } from 'vue-property-decorator'

import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Screenfull from '@/components/Screenfull/index.vue'
import SizeSelect from '@/components/SizeSelect/index.vue'

import NavbarUserProfile from './components/navbar-user-profile.vue'

import { store } from '@/store'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Screenfull,
    SizeSelect,
    NavbarUserProfile
  }
})
export default class extends Vue {
  get sidebar() {
    return store.state.app.sidebar
  }

  private toggleSideBar() {
    store.actions.app.ToggleSideBar(false)
  }
}