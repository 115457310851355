import Vue, { Component } from 'vue'
import App from './App.vue'

import { store, vueStore } from "@/store"
import router from './router';
import '@/router/permissions';

Vue.config.productionTip = false

import "normalize.css"
import i18n, { getLocale } from '@/lang'

import ElementUI from "element-ui";

import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/index.less";

import settings, { loadSettings } from './settings'

import { getSize } from './store/modules/app'
import moment from 'moment'

Vue.use(ElementUI, {
  size: getSize() || "default",
  i18n: (key: string, value: string) => i18n.t(key, value)
})

moment.locale(getLocale());

// Register global mixin
Vue.mixin({
  methods: {
    $can(key: string) {
      return store.state.login.claims.indexOf(key) >= 0;
    },
    $me() {
      return store.state.login.userInfo;
    }
  }
});

import InfoFilterBox from '@/components/InfoFilterBox/index.vue';
Vue.component("InfoFilterBox", InfoFilterBox)

import * as filters from '@/filters';
// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string]: Function, })[key]);
});

loadSettings().then(async () => {

  try {
    await authService.CheckLoginFromExternalProvider();
  } catch (err) {
    console.error('cannot upgrade authentication token.', err);
  }

  new Vue({
    router,
    store: vueStore,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

import { authService } from './modules/account/services/authService';
setInterval(async () => {
  await authService.AutoRenewToken();
}, settings.authSettings.refreshTokenInterval * 60 * 1000);
