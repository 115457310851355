
import { getLocale } from '@/lang'
import Cookies from 'js-cookie'
const sizeKey = 'size'
export const getSize = () => Cookies.get(sizeKey)
export const setSize = (size: string) => Cookies.set(sizeKey, size)

export enum DeviceType {
  Mobile,
  Desktop,
}

export interface IAppState {
  device: DeviceType
  sidebar: {
    opened: boolean
    withoutAnimation: boolean
  }
  language: string
  size: string
}

export interface IAppStoreActions {
  ToggleSideBar(withoutAnimation: boolean);
  CloseSideBar(withoutAnimation: boolean);
  ToggleDevice(device: DeviceType);
  SetLanguage(language: string);
  SetSize(size: string);
}

export interface IAppStoreGetters {

}


export const appStore = {
  PREFIX: "app",
  namespaced: true,
  state: {
    sidebar: {
      opened: true,
      withoutAnimation: false
    },

    device: DeviceType.Desktop,
    language: getLocale(),
    size: getSize() || 'default'
  },
  mutations: {
    TOGGLE_SIDEBAR(state: IAppState, withoutAnimation: boolean) {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = withoutAnimation
    },
    CLOSE_SIDEBAR(state: IAppState, withoutAnimation: boolean) {
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE(state: IAppState, device: DeviceType) {
      state.device = device
    },

    SET_LANGUAGE(state: IAppState, language: string) {
      state.language = language
    },
    SET_SIZE(state: IAppState, size: string) {
      state.size = size
      setSize(size);
    }
  },
  actions: {
    ToggleSideBar({ commit }, withoutAnimation: boolean) {
      commit("TOGGLE_SIDEBAR", withoutAnimation)
    },
    CloseSideBar({ commit }, withoutAnimation: boolean) {
      commit("CLOSE_SIDEBAR", withoutAnimation)
    },
    ToggleDevice({ commit }, device: DeviceType) {
      commit("TOGGLE_DEVICE", device)
    },
    SetLanguage({ commit }, language: string) {
      commit("SET_LANGUAGE", language)
    },
    SetSize({ commit }, size: string) {
      commit("SET_SIZE", size);
    }
  }
}


