import settings from "@/settings";
import { store } from "@/store";
import { baseRestService, AuthToken } from "@/services/_base/baseRestService";
import { loginStore } from "@/store/modules/login.store";

import QS from "qs";

class AuthServices extends baseRestService {

  constructor() {
    super();

    this.baseUrl = () => settings.authSettings.endpoint;

    this.OnError = (e) => {
      const error = {
        title: e.data.name,
        details: e.data.message,
      };
      console.error(e);
      return null;
    };

    this.OnHeadersPreparing = (headers: any) => {
      headers['Content-Type'] = "application/x-www-form-urlencoded";
      return headers
    }
  }


  public async CheckLoginFromExternalProvider() {

    if (sessionStorage.getItem(`auth_code`)) {
      const authData = new URLSearchParams();
      authData.append("code", sessionStorage.getItem('auth_code'));
      authData.append("response_type", "token");
      authData.append("client_id", settings.authSettings.clientId);
      authData.append("grant_type", "authorization_code");
      authData.append("redirect_uri", `${window.location.origin}/auth_callback.html`)

      var result = await this.Post<AuthToken>(`/connect/token`, authData);
      if (result)
        this.setAuthenticationToken(result);

      sessionStorage.removeItem('auth_code');
    }
  }

  /**
   *
   * Authentication method
   *
   * @param uri uri string for logging in
   * @param data object with email and password to authenticate user
   * @returns an AuthToken or null and set and authentication token to the local storage
   */
  public async Login(obj: account.login): Promise<AuthToken | null> {
    const authData = new URLSearchParams();
    authData.append("username", obj.userName);
    authData.append("password", obj.password);
    authData.append("scope", settings.authSettings.scopes);
    authData.append("client_id", settings.authSettings.clientId);
    authData.append("grant_type", "password");

    const response = await this.Post<AuthToken>('/connect/token', authData);

    if (response) {
      this.setAuthenticationToken(response);
      // await usersService.whoAmI();
      // await usersService.getClaims();
    }
    return response;

  }


  public async Logout() {
    await this.Post<any>("/connect/logout", null);
    this.deleteAuthenticationToken();
  }

  public async AutoRenewToken() {
    var token = await this.getAuthenticationToken();

    if (token) {
      await this.RefreshToken();
    }
  }

  /**
   * Refresh auth token in the local storage
   *
   * @returns an AuthToken or null if any error
   */
  public async RefreshToken(): Promise<AuthToken> {
    const token = await this.getAuthenticationToken();

    const refreshData = new URLSearchParams();
    refreshData.append("grant_type", "refresh_token");
    refreshData.append("scope", settings.authSettings.scopes);
    refreshData.append("client_id", settings.authSettings.clientId);
    refreshData.append("refresh_token", `${token.refresh_token}`);


    try {
      const response = await this.http.post<AuthToken>(`${settings.authSettings.endpoint}/connect/token`, refreshData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      if (response.status == 200) {
        if (response.data.expires_in) {
          response.data.expiration_date = new Date(new Date().getTime() + response.data.expires_in * 1000).getTime();
        }

        this.setAuthenticationToken(response.data);

        // await usersService.whoAmI();
        // await usersService.getClaims();
      }
      if (response.status >= 400) {
        this.deleteAuthenticationToken();
      }
      return response.data;
    } catch (err) {
      return null;
    }
  }

  public async GetAccessToken() {
    return JSON.parse(localStorage.getItem("authorizationData"))
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected setAuthenticationToken(data: AuthToken) {
    localStorage.setItem("authorizationData", JSON.stringify(data));
    baseRestService._token = data;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected deleteAuthenticationToken() {
    localStorage.removeItem("authorizationData")
    store.actions.login.setMyUserInfo(null);
    store.actions.login.setClaims([]);
    baseRestService._token = null;
  }

  public async WhoAmI(): Promise<user.whoAmI> {
    try {
      let result = await this.Get<any>("/identity/users/whoami");
      store.actions.login.setMyUserInfo(result);
      return result;
    } catch { return null; }
  }

  public async GetClaims(): Promise<any> {
    let result = await this.Get<any>("/identity/users/claims");
    store.actions.login.setClaims(result);
    return result;
  }

  public async GetSPIDProviders(): Promise<account.spidProvider[]> {
    let result = await this.Get<any>("/spid/providers");
    return result;
  }

  public async DoSpidLogin(provider: string) {
    var params = QS.stringify({
      provider,
      returnUrl: `/connect/authorize?client_id=${settings.authSettings.clientId}&response_type=code&redirect_uri=${window.location.origin}/auth_callback.html`
    })
    window.open(`${this.baseUrl()}/spid/Login?${params}`);
  }
}

export const authService = new AuthServices();
