import Vue from "vue";
import Vuex from "vuex";
import { ActionTree, GetterTree } from "vuex";
import { RouteConfig } from "vue-router";

import { permissionRoutes, constantRoutes } from '@/router';
import { loginStore } from "./login.store";

Vue.use(Vuex);
const hasPermission = (claims: string[], route: RouteConfig) => {
  if (route.meta && route.meta.claims && route.meta.claims.length > 0) {
    if (route.meta.adempientePeriodoFormativoPrecedente === null 
     || route.meta.adempientePeriodoFormativoPrecedente === undefined
     || !loginStore.state.userInfo) {
      return claims.some(role => route.meta.claims.includes(role));
    }
    
    if (route.meta.adempientePeriodoFormativoPrecedente !== loginStore.state.userInfo?.adempienteTriennioPrecedente)
      return false;
    return claims.some(role => route.meta.claims.includes(role));
  } else {
    return true;
  }
};

export const filterAsyncRoutes = (routes: RouteConfig[], claims: string[]) => {
  const res: RouteConfig[] = [];
  routes.forEach(route => {
    const r = { ...route };
    if (hasPermission(claims, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, claims);
      }
      res.push(r);
    }
  });
  return res;
};

export interface PermissionStoreActions {
  generateRoutes(claims: string[]);
}
export interface PermissionStoreGetters {
  routes: () => RouteConfig[];
  dynamicRoutes: () => RouteConfig[];
}
export interface PermissionStoreModel {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

export const permissionStore = {
  PREFIX: "permission",
  namespaced: true,
  state: {
    routes: [],
    dynamicRoutes: []
  } as PermissionStoreModel,
  getters: {
    routes: (state: PermissionStoreModel) => (): RouteConfig[] => state.routes,
    dynamicRoutes: (state: PermissionStoreModel) => (): RouteConfig[] => state.dynamicRoutes
  } as GetterTree<PermissionStoreModel, PermissionStoreModel>,
  mutations: {
    SET_ROUTES(state: PermissionStoreModel, routes: RouteConfig[]) {
      state.routes = constantRoutes().concat(routes);
      state.dynamicRoutes = routes;
    }
  },
  actions: {
    generateRoutes(context, roles: string[]) {
      const accessedRoutes = filterAsyncRoutes(permissionRoutes(), roles);
      context.commit('SET_ROUTES', accessedRoutes);
    }
  } as ActionTree<PermissionStoreModel, any>,
};



