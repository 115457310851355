import Component from "vue-class-component";
import Vue from "vue";
import { authService } from "./modules/account/services/authService";

@Component({})
export default class App extends Vue {

  // async mounted() {
  //   await this.checkAuth();
  // }

  // async updated() {
  //   await this.checkAuth();
  // }

  // async checkAuth(){
  //   var token = await authService.GetAccessToken();

  //   let needlogin = false;
  //   if (!token) needlogin = true;

  //   try {
  //     await authService.WhoAmI();
  //     await authService.GetClaims();
  //   } catch (err) {
  //     needlogin = true;
  //   }

  //   const whiteList = ['/register', '/recoverPassword', '/changeCredential', '/addCredential', '/resetPassword', '/emailConfirm'];
  //   if (this.$route.name !== 'login' && needlogin && !whiteList.some(s => this.$route.path.indexOf(s) !== -1)) {
  //     this.$router.push({ name: 'login' })
  //   }
  // }
}