import { compile } from 'path-to-regexp';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteRecord, Route } from 'vue-router';

@Component({})
export default class BreadCrumb extends Vue {
  private breadcrumbs: RouteRecord[] = [];

  @Watch('$route')
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb();
  }

  created() {
    this.getBreadcrumb();
  }

  private getBreadcrumb() {
    let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
    const first = matched[0];
    if (!this.isDashboard(first)) {
      matched = [{ path: '/dashboard', meta: { title: 'Home' } } as unknown as RouteRecord].concat(matched);
    }
    this.breadcrumbs = matched.filter(item => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
  }

  private isDashboard(route: RouteRecord) {
    const name = route && route.name;
    if (!name) {
      return false;
    }
    return name.trim().toLocaleLowerCase() === 'dashboard'.toLocaleLowerCase();
  }

  private handleLink(item: any) {
    const { redirect, path } = item;
    if (redirect) {
      this.$router.push(redirect).catch(err => { });
      return;
    }
    this.$router.push({ path: path }).catch(err => {
      console.warn(err);
    });
  }
}