export interface ICorsiApertiState {
  query: giornalista.corso.queryParams
}

export interface ICorsiApertiActions {
  setQuery(model: giornalista.corso.queryParams);
  clear();
}

export interface ICorsiApertiGetters {
}

export const corsiApertiStore = {
  PREFIX: "corsiAperti",
  namespaced: true,
  state: {
    query: {
      search: null,
      referenteId: null,
      enteId: null,
      deontologico: null,
      gratuito: null,
      continuativo: null,
      virtuale: null,
      ammesso: null,
      cancellato: null,
      frequentato: null,
      orderField: 'Data',
      orderDescending: true,
      page: 1,
      take: 20
    } as giornalista.corso.queryParams
  },
  mutations: {
    SET_QUERY(state : ICorsiApertiState, model: giornalista.corso.queryParams){
      state.query = model;
    },
    CLEAR(state: ICorsiApertiState){
      state.query = {
        search: null,
        stato: null,
        pofId: null,
        referenteId: null,
        enteId: null,
        deontologico: null,
        gratuito: null,
        continuativo: null,
        virtuale: null,
        orderField: 'Data',
        orderDescending: true,
        page: 1,
        take: 20
      } as giornalista.corso.queryParams
    }
  },
  actions: {
    setQuery({ commit }, model: giornalista.corso.queryParams) {
      commit("SET_QUERY", model)
    },
    clear({ commit }){
      commit("CLEAR")
    }
  }
}
