import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import ResizeMixin from './mixin/resize';

@Component({
  name: 'CleanLayout',
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: true,
      openSidebar: false,
      withoutAnimation: false
    };
  }

  get key() {
    return this.$route.path;
  }

  get embeddedIframe(): boolean {
    return window.location !== window.parent.location;
  }
}
