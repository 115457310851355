import { isExternal } from '@/utils/validate'
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SidebarLink'
})
export default class extends Vue {
  @Prop({ required: true })
  to: string;
  
  get isExternal() {
    return isExternal(this.to)
  }

  get type() {
    if (this.isExternal) {
      return 'a'
    }
    return 'router-link'
  }

  linkProps(to) {
    if (this.isExternal) {
      return {
        href: to,
        target: '_blank',
        rel: 'noopener'
      }
    }
    return {
      to: to
    }
  }
}